import { Injectable, ErrorHandler, Inject } from '@angular/core';
import { HttpEvent } from '@angular/common/http';
import { captureException } from '@sentry/angular-ivy';

import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';
import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';

import { OperatorFunction, catchError, throwError, retry, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExceptionService implements ErrorHandler {
  public handleError(error: Error): void {
    console.error(error);
    if (this.config.env !== 'local') {
      captureException(error);
    }
  }

  public handleHttpError<T>(): OperatorFunction<HttpEvent<T>, HttpEvent<T>> {
    return (r$) =>
      r$.pipe(
        retry(3),
        tap((event: HttpEvent<T>) => {
          if (event.type === 4 && event.status >= 400) {
            throw new Error(event.statusText);
          }
        }),
        catchError((error) => {
          this.handleError(error);
          return throwError(() => error);
        })
      );
  }

  constructor(
    @Inject(RUNTIME_CONFIGURATION)
    private readonly config: RuntimeConfigurationModel
  ) {}
}
