function watch(propName, options) {
    return (protoOrDescriptor, name) => {
        const { update } = protoOrDescriptor;
        const watchOptions = Object.assign({ waitUntilFirstUpdate: false }, options);
        /* eslint-disable-next-line no-param-reassign, func-names */
        protoOrDescriptor.update = function (changedProps) {
            if (changedProps.has(propName)) {
                const oldValue = changedProps.get(propName);
                const newValue = this[propName];
                if (oldValue !== newValue) {
                    if (!(watchOptions === null || watchOptions === void 0 ? void 0 : watchOptions.waitUntilFirstUpdate) || this.hasUpdated) {
                        this[name].call(this, oldValue, newValue);
                    }
                }
            }
            update.call(this, changedProps);
        };
    };
}
export { watch };
