import { __decorate, __metadata } from "tslib";
import { html, LitElement } from 'lit';
import { state } from 'lit/decorators.js';
import { emit } from '../../internal/event.js';
import { ARC_EVENTS } from '../../internal/constants/eventConstants.js';
import styles from './arc-bottombar.styles.js';
import '../icon-button/arc-icon-button.js';
import '../icon/accessibility/arc-icon-accessibility.js';
/**
 * @slot - This slot is used to add icon-buttons to the bottom bar.
 *
 * @event arc-show-accessibility - Emitted when the built-in accessibility button is pressed.
 *
 * @ssr - True
 */
export default class ArcBottombar extends LitElement {
    constructor() {
        super(...arguments);
        /** @internal - State that stores the max tab count */
        this.tabs = 5;
    }
    /* Whenever a tab changes, update the tabCount */
    _handleTabChange(e) {
        const isTab = (element) => element.tagName === 'ARC-ICON-BUTTON';
        const nodes = e.target.assignedElements({ flatten: true });
        const navTabs = nodes.filter(isTab);
        this.tabCount = navTabs.length;
        if (this.tabCount > this.tabs) {
            // TODO: ARC-12 Put the slotted tabs inside an arc-dropdown component once they exceed the given tab count
            this.log(`Please limit your tab count to a maximum of ${this.tabs} tabs`);
        }
    }
    log(msg) {
        // eslint-disable-next-line no-console
        console.log(msg);
    }
    /* Emit an event to show the accessibility panel */
    emitAccessibility() {
        this.log('Emitting arc-show-accessibility event');
        emit(this, ARC_EVENTS.showAccessibility);
    }
    render() {
        return html `
      <nav id="main" aria-label="mobile navigation">
        <slot @slotchange=${this._handleTabChange}></slot>
        <arc-icon-button @click=${this.emitAccessibility}>
          <arc-icon-accessibility slot="icon"></arc-icon-accessibility>
          Accessibility Panel
        </arc-icon-button>
      </nav>
    `;
    }
}
/** @internal */
ArcBottombar.tag = 'arc-bottombar';
ArcBottombar.styles = styles;
__decorate([
    state(),
    __metadata("design:type", Number)
], ArcBottombar.prototype, "tabs", void 0);
__decorate([
    state(),
    __metadata("design:type", Number)
], ArcBottombar.prototype, "tabCount", void 0);
