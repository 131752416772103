import { prefersReducedMotion } from './preferences.js';
const defaultAnimationRegistry = new Map();
const customAnimationRegistry = new WeakMap();
function ensureAnimation(animation) {
    if (!animation) {
        return { keyframes: [], options: { duration: 0 } };
    }
    return animation;
}
/*
Sets a default animation. Components should use the `name.animation` for primary animations and `name.part.animation`
for secondary animations, e.g. `dialog.show` and `dialog.overlay.show`. For modifiers, use `drawer.showTop`.
*/
function setDefaultAnimation(animationName, animation) {
    defaultAnimationRegistry.set(animationName, ensureAnimation(animation));
}
/* Sets a custom animation for the specified element. */
function setAnimation(el, animationName, animation) {
    customAnimationRegistry.set(el, Object.assign(Object.assign({}, customAnimationRegistry.get(el)), { [animationName]: ensureAnimation(animation) }));
}
/* Retrieves an element's animation. Falls back to the default if no animation is found. */
function getAnimation(el, animationName) {
    const customAnimation = customAnimationRegistry.get(el);
    /* Check for a custom animation. */
    if (customAnimation && customAnimation[animationName]) {
        return customAnimation[animationName];
    }
    /* Check for a default animation. */
    const defaultAnimation = defaultAnimationRegistry.get(animationName);
    if (defaultAnimation) {
        return defaultAnimation;
    }
    /* Fall back to an empty animation. */
    return { keyframes: [], options: { duration: 0 } };
}
/* Animates an element using keyframes. Returns a promise that resolves after the animation completes or gets canceled. */
function startAnimations(el, keyframes, options) {
    return new Promise((resolve) => {
        if ((options === null || options === void 0 ? void 0 : options.duration) === Infinity) {
            throw new Error('Promise-based animations must be finite.');
        }
        const animation = el.animate(keyframes, Object.assign(Object.assign({}, options), { 
            /* c8 ignore next */
            duration: prefersReducedMotion() ? 0 : options.duration }));
        animation.addEventListener('cancel', resolve, { once: true });
        animation.addEventListener('finish', resolve, { once: true });
    });
}
/* Stops all active animations on the target element. Returns a promise that resolves after all animations are canceled. */
function stopAnimations(el) {
    return Promise.all(el.getAnimations().map((animation) => new Promise((resolve) => {
        const handleAnimationEvent = requestAnimationFrame(resolve);
        animation.addEventListener('cancel', () => handleAnimationEvent, {
            once: true,
        });
        animation.cancel();
    })));
}
/* Parses a delay and returns the number in milliseconds */
function parseDuration(delay) {
    const delayStr = delay.toString().toLowerCase();
    if (delayStr.indexOf('ms') > -1) {
        return parseFloat(delayStr);
    }
    if (delayStr.indexOf('s') > -1) {
        return parseFloat(delayStr) * 1000;
    }
    return parseFloat(delayStr);
}
/*
We can't animate `height: auto`, but we can calculate the height and shim keyframes by replacing it with the
element's scrollHeight before the animation.
 */
function shimKeyframesHeightAuto(keyframes, calculatedHeight) {
    return keyframes.map((keyframe) => (Object.assign(Object.assign({}, keyframe), { height: keyframe.height === 'auto' ? `${calculatedHeight}px` : keyframe.height })));
}
export { setDefaultAnimation, setAnimation, getAnimation, startAnimations, stopAnimations, parseDuration, shimKeyframesHeightAuto, };
