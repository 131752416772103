import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ProjectsStore } from '@core/stores/projects.store';
import { first } from 'rxjs';

export const LOAD_PROJECTS: CanActivateFn = () => {
  const projectsStore = inject(ProjectsStore);
  projectsStore.all$.pipe(first()).subscribe((projects) => {
    if (!projects.length) {
      projectsStore.load();
    }
  });
  return true;
};
