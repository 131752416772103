import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';
import { AuthStore } from '@core/stores/auth.store';

import '@arc-web/components/src/components/container/arc-container';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'scale-root',
  templateUrl: './app.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrl: './app.component.scss',
})
export class AppComponent implements AfterViewInit {
  private readonly runtimeConfig = inject(RUNTIME_CONFIGURATION);

  title = this.runtimeConfig.title;

  constructor(private readonly authStore: AuthStore) {}

  public ngAfterViewInit(): void {
    this.authStore.updateAuthenticatedState();
  }
}
