import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AccountInfo, EventType } from '@azure/msal-browser';
import { PopupRequest } from '@azure/msal-browser';
import {
  MgtPerson,
  MgtPersonCard,
  Providers,
  SimpleProvider,
  ProviderState,
} from '@microsoft/mgt';
import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
} from '@azure/msal-angular';

import { ComponentStore } from '@ngrx/component-store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  map,
  firstValueFrom,
  tap,
  switchMap,
  EMPTY,
  filter,
  first,
} from 'rxjs';

interface State {
  accountInfo: AccountInfo | null;
}

@Injectable({ providedIn: 'root' })
export class AuthStore extends ComponentStore<State> {
  private readonly msalService = inject(MsalService);
  private readonly config = inject<PopupRequest>(MSAL_GUARD_CONFIG);
  private readonly router = inject(Router);
  private readonly initilized$ = this.msalService
    .initialize()
    .pipe(takeUntilDestroyed());
  private readonly accountUpdated$ = inject(
    MsalBroadcastService
  ).msalSubject$.pipe(
    takeUntilDestroyed(),
    filter(
      (msg) =>
        msg.eventType === EventType.ACCOUNT_ADDED ||
        msg.eventType === EventType.ACCOUNT_REMOVED ||
        msg.eventType === EventType.LOGIN_SUCCESS ||
        msg.eventType === EventType.LOGOUT_SUCCESS
    )
  );

  public readonly accountInfo$ = this.select(
    ({ accountInfo }) => accountInfo
  ).pipe(takeUntilDestroyed());
  public readonly authenticated$ = this.accountInfo$.pipe(
    map(Boolean),
    takeUntilDestroyed()
  );

  constructor() {
    super({ accountInfo: null });
    MgtPerson.config.useContactApis = false;
    MgtPersonCard.config.useContactApis = false;
    MgtPersonCard.config.sections.profile = false;
    MgtPersonCard.config.sections.organization = false;
    MgtPersonCard.config.sections.files = false;
    MgtPersonCard.config.sections.mailMessages = false;
    Providers.globalProvider = new SimpleProvider(
      async (scopes) =>
        await firstValueFrom(
          this.msalService
            .acquireTokenSilent({ scopes })
            .pipe(map((result) => result.accessToken))
        ),
      async () =>
        await firstValueFrom(
          this.msalService.loginPopup(this.config).pipe(
            tap(() => this.updateAuthenticatedState()),
            switchMap(() => EMPTY)
          )
        ),
      async () =>
        await firstValueFrom(
          this.msalService.logoutPopup().pipe(
            tap(() => this.updateAuthenticatedState()),
            switchMap(() => EMPTY)
          )
        )
    );
    this.msalService.instance.enableAccountStorageEvents();
    this.accountUpdated$.subscribe(() => this.updateAuthenticatedState());
    this.initilized$.subscribe(() => this.updateAuthenticatedState());
  }

  public login(): void {
    this.msalService.loginPopup(this.config).pipe(first()).subscribe();
  }

  public updateAuthenticatedState(): void {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts.length > 0) {
      const account = accounts[0];
      Providers.globalProvider.setState(ProviderState.SignedIn);
      this.msalService.instance.setActiveAccount(account);
      this.setState({ accountInfo: account });
    } else {
      Providers.globalProvider.setState(ProviderState.SignedOut);
      this.msalService.instance.setActiveAccount(null);
      this.setState({ accountInfo: null });
      this.router.navigateByUrl('landing-page');
    }
  }
}
