var PhIconX_1;
import { __decorate, __metadata } from "tslib";
/* GENERATED FILE */
import { html, svg, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { FONT_SIZES, } from '../../../internal/constants/styleConstants.js';
import styles from '../../icon/icon.styles.js';
/**
 * @cssproperty --icon-color - Set the color of the icon.
 *
 * @ssr - True
 */
let PhIconX = PhIconX_1 = class PhIconX extends LitElement {
    constructor() {
        super(...arguments);
        /** Set the size of the icon. */
        this.size = FONT_SIZES.medium;
        /** Set the rotation of the icon. */
        this.rotation = 0;
    }
    render() {
        return html `
      <svg
        id="main"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        role=${ifDefined(this.label ? 'img' : undefined)}
        aria-label=${ifDefined(this.label || undefined)}
        aria-hidden=${ifDefined(this.label ? undefined : 'true')}
        style=${styleMap({
            transform: this.rotation ? `rotate(${this.rotation}deg)` : null,
            height: `var(--arc-font-size-${this.size})`,
            width: `var(--arc-font-size-${this.size})`,
        })}
      >
        ${PhIconX_1.svg}
      </svg>
    `;
    }
};
/** @internal */
PhIconX.tag = 'ph-icon-x';
/** @internal */
PhIconX.styles = styles;
/** @internal */
PhIconX.svg = svg `<path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"/>`;
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], PhIconX.prototype, "label", void 0);
__decorate([
    property({ type: String, reflect: true }),
    __metadata("design:type", String)
], PhIconX.prototype, "size", void 0);
__decorate([
    property({ type: Number }),
    __metadata("design:type", Number)
], PhIconX.prototype, "rotation", void 0);
PhIconX = PhIconX_1 = __decorate([
    customElement('ph-icon-x')
], PhIconX);
export default PhIconX;
