import { html, LitElement } from 'lit';
import styles from './arc-spinner.styles.js';
/**
 * @cssproperty --stroke-color - Set the color of the loader.
 * @cssproperty --track-width - Set the thickness of the track.
 *
 * @ssr - True
 */
export default class ArcSpinner extends LitElement {
    render() {
        return html `
      <svg id="main" aria-busy="true" aria-live="polite">
        <circle id="track" cx="0.5em" cy="0.5em" r="0" />
        <circle id="indicator" cx="0.5em" cy="0.5em" r="0" />
      </svg>
    `;
    }
}
/** @internal */
ArcSpinner.tag = 'arc-spinner';
ArcSpinner.styles = styles;
