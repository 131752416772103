import { StaticProvider } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalService,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';

function createClient(
  config: RuntimeConfigurationModel
): PublicClientApplication {
  const { auth } = config;
  return new PublicClientApplication({ auth });
}

function createGuardConfig(): MsalGuardConfiguration {
  return <MsalGuardConfiguration>{
    interactionType: InteractionType.Popup,
    authRequest: { scopes: ['user.read', 'group.read.all'] },
  };
}

function createInterceptorConfig(
  config: RuntimeConfigurationModel
): MsalInterceptorConfiguration {
  const { apiUrl, auth } = config;
  const { clientId } = auth;
  const resources = new Map<string, (string | ProtectedResourceScopes)[]>();
  resources.set(`${apiUrl}/*`, [`api://${clientId}/user_impersonation`]);
  resources.set('https://graph.microsoft.com/v1.0/groups ', ['group.read.all']);
  return <MsalInterceptorConfiguration>{
    interactionType: InteractionType.Popup,
    protectedResourceMap: resources,
  };
}

export function createProviders(
  config: RuntimeConfigurationModel
): StaticProvider[] {
  return [
    {
      provide: MSAL_INSTANCE,
      useValue: createClient(config),
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: createGuardConfig(),
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useValue: createInterceptorConfig(config),
    },
    { provide: MsalGuard, useClass: MsalGuard },
    { provide: MsalService, useClass: MsalService },
    { provide: MsalBroadcastService, useClass: MsalBroadcastService },
  ];
}
