import { ApplicationConfig, ErrorHandler } from '@angular/core';
import { MsalInterceptor } from '@azure/msal-angular';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';
import { ExceptionService } from '@core/services/exception.service';
import { createProviders as createMsalProviders } from '@core/utils/msal';
import { createProviders as createSentryProviders } from '@core/utils/sentry';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

import { appRoutes } from './app.routes';

export function createConfig(
  config: RuntimeConfigurationModel
): ApplicationConfig {
  return {
    providers: [
      { provide: RUNTIME_CONFIGURATION, useValue: config },
      { provide: ErrorHandler, useExisting: ExceptionService },
      provideAnimations(),
      provideRouter(appRoutes, withComponentInputBinding()),
      ...createMsalProviders(config),
      ...createSentryProviders(),
      { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
      provideHttpClient(withInterceptorsFromDi()),
    ],
  };
}
