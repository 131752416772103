import { APP_INITIALIZER, ErrorHandler, StaticProvider } from '@angular/core';
import { Router } from '@angular/router';

import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';

import {
  BrowserOptions,
  TraceService,
  BrowserTracing,
  Replay,
  Feedback,
  createErrorHandler,
  init,
} from '@sentry/angular-ivy';

export function initSentry(config: RuntimeConfigurationModel): void {
  if (config.sentry.enable) {
    const { options, integrations } = config.sentry;
    const browserOptions: BrowserOptions = { ...options, integrations: [] };
    const intergrationObjects: BrowserOptions['integrations'] = [];
    for (const i of Object.keys(integrations)) {
      switch (i) {
        case 'browser-tracing': {
          const browserTracing = new BrowserTracing(integrations[i]);
          intergrationObjects.push(browserTracing);
          break;
        }
        case 'replay': {
          const replay = new Replay(integrations[i]);
          intergrationObjects.push(replay);
          break;
        }
        case 'feedback': {
          const feedback = new Feedback(integrations[i]);
          intergrationObjects.push(feedback);
          break;
        }
      }
    }
    browserOptions.integrations = intergrationObjects;
    init(browserOptions);
  }
}

export function createProviders(): StaticProvider[] {
  return [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({ showDialog: true }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ];
}
