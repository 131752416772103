import { CONTAINER_THEME_PREFERENCES, } from '../../container/constants/ContainerConstants.js';
import { FONT_SIZES, FONT_SPACING, } from '../../../internal/constants/styleConstants.js';
export const ACCESSIBILITY_OPTIONS = [
    {
        name: 'colourAdjustments',
        options: {
            theme: Object.values(CONTAINER_THEME_PREFERENCES),
        },
    },
    {
        name: 'contentAdjustments',
        options: {
            fontSize: [FONT_SIZES.medium, FONT_SIZES.large, FONT_SIZES['x-large']],
            lineHeight: [FONT_SPACING.dense, FONT_SPACING.normal, FONT_SPACING.loose],
            letterSpacing: [
                FONT_SPACING.dense,
                FONT_SPACING.normal,
                FONT_SPACING.loose,
            ],
            highLegibilityFonts: null,
            highlightLinks: null,
            plainText: null,
        },
    },
];
