import { bootstrapApplication } from '@angular/platform-browser';

import { initSentry } from '@core/utils/sentry';
import { AppComponent } from '@app/app.component';
import { createConfig } from '@app/app.config';

fetch('configuration/config.json')
  .then((response) => response.json())
  .then(async (config) => {
    initSentry(config);
    const appConfig = createConfig(config);
    return bootstrapApplication(AppComponent, appConfig).catch(console.error);
  })
  .catch(console.error);
