import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { LandingPageComponent } from '@landing-page/containers/landing-page/landing-page.component';
import { LOAD_PROJECTS } from '@core/guards/load-projects.guard';

export const appRoutes: Route[] = [
  { path: 'landing-page', component: LandingPageComponent },
  {
    path: 'projects-page',
    loadComponent: () =>
      import('@projects/containers/projects-page/projects-page.component').then(
        (mod) => mod.ProjectsPageComponent
      ),
    canActivate: [MsalGuard, LOAD_PROJECTS],
  },
  {
    path: 'outputs-page/:projectNumber',
    loadComponent: () =>
      import('@outputs/containers/outputs-page/outputs-page.component').then(
        (mod) => mod.OutputsPageComponent
      ),
    canActivate: [MsalGuard, LOAD_PROJECTS],
  },
  {
    path: 'setup',
    loadComponent: () =>
      import(
        '@setup/containers/setup-container/setup-container.component'
      ).then((mod) => mod.SetupContainerComponent),
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            outlet: 'setup-sidebar',
            loadComponent: () =>
              import(
                '@setup/components/welcome-aside/welcome-aside.component'
              ).then((mod) => mod.WelcomeAsideComponent),
          },
          {
            path: '',
            title: 'Key project details',
            outlet: 'setup-content',
            loadComponent: () =>
              import(
                '@setup/containers/key-project-details/key-project-details.component'
              ).then((mod) => mod.KeyProjectDetailsComponent),
          },
        ],
      },
      {
        path: 'user-sidebar',
        outlet: 'setup-sidebar',
        loadComponent: () =>
          import('@setup/components/user-aside/user-aside.component').then(
            (mod) => mod.UserAsideComponent
          ),
      },
    ],
  },
  { path: '', redirectTo: '/landing-page', pathMatch: 'full' },
];
