import { __decorate, __metadata } from "tslib";
import { html, LitElement } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { watch } from '../../internal/watch.js';
import { CONTAINER_THEME_PREFERENCES, } from './constants/ContainerConstants.js';
import styles from './arc-container.styles.js';
import '../navbar/arc-navbar.js';
import '../accessibility/arc-accessibility.js';
import '../bottombar/arc-bottombar.js';
/**
 * @slot default - The container's content.
 * @slot nav - The container's navbar.
 * @slot accessibility - The accessibility drawer.
 * @slot side - The container's sidebar.
 * @slot bottom - The container's bottom bar.
 *
 * @ssr - True
 */
export default class ArcContainer extends LitElement {
    constructor() {
        super(...arguments);
        /** Set the starting theme for the container. Once loaded, the built-in accessibility will be responsible for this property. */
        this.theme = CONTAINER_THEME_PREFERENCES.auto;
        /** Set the container to fullscreen mode. This hides the padding, margin and gap values. */
        this.fullscreen = false;
    }
    handleThemeChange() {
        /* If the provided theme is not valid, force auto theme */
        if (!(this.theme in CONTAINER_THEME_PREFERENCES)) {
            this.theme = CONTAINER_THEME_PREFERENCES.auto;
        }
    }
    /* Listen to keyboard input on the page */
    connectedCallback() {
        super.connectedCallback();
        /* If the app provided theme is not valid, force auto theme */
        if (!(this.theme in CONTAINER_THEME_PREFERENCES)) {
            this.theme = CONTAINER_THEME_PREFERENCES.auto;
        }
        /* Store a reference of the app-defined theme */
        this._appPreferredTheme = this.theme;
    }
    /* Update the theme when the @arc-accessibility-change event emits */
    handleAccessibilityChange(event) {
        const { preferences } = event.detail;
        const { theme } = preferences;
        /* Make sure that the new theme is valid */
        if (!!theme && theme in CONTAINER_THEME_PREFERENCES) {
            this.theme = theme;
            return;
        }
        /* When the preferences are reset, restore the appPreferredTheme instead */
        this.theme = this._appPreferredTheme;
    }
    /* Trigger the show event of the arc-accessibility component */
    showAccessibility() {
        this.accessibility.open = true;
    }
    render() {
        return html `
      <div id="main">
        <slot
          id="nav"
          name="nav"
          @arc-show-accessibility=${this.showAccessibility}
        >
          <arc-navbar
            @arc-show-accessibility=${this.showAccessibility}
          ></arc-navbar>
        </slot>
        <div
          id="container"
          class=${classMap({
            container: true,
            'container--fullscreen': this.fullscreen,
        })}
        >
          <slot name="side"></slot>
          <div id="content">
            <slot></slot>
          </div>
        </div>
        <slot
          name="accessibility"
          @arc-accessibility-change=${this.handleAccessibilityChange}
        >
          <arc-accessibility
            id="accessibility"
            @arc-accessibility-change=${this.handleAccessibilityChange}
          ></arc-accessibility>
        </slot>
        <slot name="bottom">
          <arc-bottombar
            @arc-show-accessibility=${this.showAccessibility}
          ></arc-bottombar>
        </slot>
      </div>
    `;
    }
}
/** @internal */
ArcContainer.tag = 'arc-container';
/** @internal */
ArcContainer.styles = styles;
__decorate([
    query('#main'),
    __metadata("design:type", HTMLElement)
], ArcContainer.prototype, "container", void 0);
__decorate([
    query('#accessibility'),
    __metadata("design:type", Function)
], ArcContainer.prototype, "accessibility", void 0);
__decorate([
    property({ type: String, reflect: true }),
    __metadata("design:type", String)
], ArcContainer.prototype, "theme", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], ArcContainer.prototype, "fullscreen", void 0);
__decorate([
    watch('theme'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ArcContainer.prototype, "handleThemeChange", null);
