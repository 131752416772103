<div class="landing-page">
  <section class="landing-page__actions">
    <div>
      <h1 class="scale-title">SCALE</h1>
      <p>Site selection is complex, but it doesn’t need to be difficult.</p>
      <button
        *ngIf="(authenticated$ | async) === false"
        mat-flat-button
        color="primary"
        (click)="handleLogin()"
      >
        Login <mat-icon iconPositionEnd>arrow_circle_right</mat-icon>
      </button>
      <button
        *ngIf="authenticated$ | async"
        mat-flat-button
        color="primary"
        routerLink="/projects-page"
      >
        Enter <mat-icon iconPositionEnd>arrow_circle_right</mat-icon>
      </button>
      <hr />
      <div class="landing-page__links">
        <a href="" class="sandbox-link">
          <img src="assets/sandbox.svg" alt="" />
          <span>Go to <strong>SCALE Sandbox</strong></span>
          <mat-icon color="primary">arrow_circle_right</mat-icon>
        </a>
        <a href="" class="icon-link">
          <img src="assets/documentation.png" alt="" />
          Documentation
        </a>
        <a href="" class="icon-link">
          <img src="assets/support.png" alt="" />
          Support
        </a>
      </div>
    </div>
  </section>
  <section class="landing-page__footer">
    <img src="assets/ARUP-logo.svg" alt="Arup" />
    2024
  </section>
</div>
